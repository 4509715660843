<template>
    <div class="search-order-all">
        <!-- 头部导航 -->
        <m-header title="Guest check order" />

        <div class="phone-box">
            <div class="input-box">
                <p>Order number</p>
                <div class="code-box" :class="{'active': focus == 0}">
                    <input type="number" placeholder="enter your order number" @focus="focus = 0" v-model="order">
                </div>
            </div>
            <div class="input-box">
                <p>Phone / Email</p>
                <div class="code-box" :class="{'active': focus == 1}">
                    <input type="text" placeholder="enter your Phone / Email" @focus="focus = 1" v-model="phone">
                </div>
            </div>
            <div class="input-box">
                <p>Code</p>
                <div class="code-box" :class="{'active': focus == 2}">
                    <input type="text" placeholder="enter your Code" @focus="focus = 2" v-model="code">
                    <span @click="getCodeHandle">
                        {{second + (typeof(second) == 'number' ? ' s' : '')}}
                        <van-loading color="#1989fa" size="20px" v-if="second == ''" style="margin:0" />
                    </span>
                </div>
            </div>
        </div>
        <div class="submit-search" @click="viewOrderHandle">Check</div>
    </div>
</template>

<script>
/* eslint-disable*/
import MHeader  from '@/components/zh/m-header.vue'
import { phone_code, email_code, getOrderDetail } from '@/api/zh/login'
export default {
    components: { MHeader },
    data() {
        return {
            focus: -1,
            second: 'send',
            timer: null,
            phone: '',
            code: '',
            order: ''
        }
    },
    methods: {
        getCodeHandle() {
            // 获取验证码
            if(this.isPhoneOrEmail() == 1) {
                if(this.phone == '' || !/^[0-9]*$/.test(this.phone)) {
                    this.$notify({ type: 'warning', duration: 2000, message: 'Please enter the correct mobile phone number' })
                    return false
                }
                this.second = ''
                let params = { 
                    phone    : this.phone,
                    area_code: this.phone.length == 11 ? 86: 1,
                    model    : ''
                }
                phone_code(params).then(res => {
                    this.sendSuccess(res)
                })
            } else {
                this.second = ''
                let params = { 
                    email    : this.phone,
                    model    : ''
                }
                email_code(params).then(res => {
                    this.sendSuccess(res)
                })
            }
        },
        sendSuccess(res) {
            if(res) {
                this.second = 60
                this.timer = setInterval(() => {
                    this.second--
                    if(this.second <= 0) {
                        this.second = 'send'
                        clearInterval(this.timer)
                    }
                }, 1000)
            } else {
                this.second = 'send'
                this.$notify({ type: 'warning', duration: 2000, message: res.msg })
            }
        },
        isPhoneOrEmail() {
            let email_patt = /^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/
            return email_patt.test(this.phone) ? 2 : 1
        },
        viewOrderHandle() {
            // 查询订单
            if(this.order == '') {
                this.$notify({ type: 'warning', duration: 2000, message: 'Please enter your order number' })
                return false
            }
            if(this.isPhoneOrEmail() == 1 && (this.phone == '' || !/^[0-9]*$/.test(this.phone))) {
                this.$notify({ type: 'warning', duration: 2000, message: 'Please enter the correct mobile phone number' })
                return false
            }
            if(this.codec == '') {
                this.$notify({ type: 'warning', duration: 2000, message: 'Please enter your code' })
                return false
            }

            this.$store.commit('load')
            
            let params = {
                order_title: this.order,
                phone: this.phone,
                email: this.phone,
                code: this.code,
                check_type: this.isPhoneOrEmail()
            }

            getOrderDetail(params).then(res => {
                if(res) {
                    localStorage.setItem('token', res.access_token)
                    localStorage.setItem('token2', res.access_token)
                    this.$router.push(`/en/order/detail/${res.order_id}`)
                }
            }).finally(() => {
                this.$store.commit('unload')
            })
        }
    }
}
</script>

<style scoped lang="less">
.search-order-all{width: 100%;height: 100%;background-color: #F5F9FF;padding-top:44px;overflow-x: hidden;overflow-y: auto;
    .phone-box{margin:16px auto;width:calc(100% - 32px);background-color: #fff;box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 10px;padding:0 32px 10px 32px;box-sizing: border-box;padding-top:30px;
        .input-box{
            >p{font-size: 14px;line-height: 14px;color: #000000;margin-bottom:10px}
            .code-box{background: #FFFFFF;border: 1px solid #E6E6E6;border-radius: 6px;margin-bottom:25px;display: flex;
            box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.203611);
                span{font-size: 14px;line-height: 14px;color: #2D77E0;height:30px;display: block;line-height: 30px;margin-top:9px;
                border-left:1px solid #E6E6E6;padding:0 10px;word-break: keep-all;}
                
            }
            input{border:none;outline: none;height:48px;background-color: transparent;flex:1;display: block;min-width:0;padding:0 10px;
            font-size: 14px;}
            .active{box-shadow: 0px 0px 0px 2px rgba(50, 133, 250, 0.151);border-color: #3284FA;}
        }
    }
    .submit-search{background: linear-gradient(295.9deg, #F03C18 12.2%, #F06448 82.1%);border-radius: 25px;width:260px;height:44px;
    text-align: center;color:#fff;line-height: 44px;font-weight: bold;margin:40px auto;
    }
}
</style>